import BreadCrumb from "Common/BreadCrumb";
import { PaginationState } from "@tanstack/react-table";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import { MoreHorizontal, Eye, FileEdit, Trash2 } from "lucide-react";
import { Dropdown } from "Common/Components/Dropdown";
import { Download, Plus, Search, SlidersHorizontal } from "lucide-react";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import DeleteModal from "Common/DeleteModal";
import callApi from "Common/utils/apiCaller";
import { Pencil } from "lucide-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import _ from "lodash";
import Drawer from "Common/Components/Drawer";
import { X } from "lucide-react";

const UserList = () => {
  const [adminData, setAdminData] = useState([]);
  const [search, setSearch] = useState<any>();
  const [pageCount, setPageCount] = useState(0);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState(0);
  const [render, setRender] = useState(false);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);
  const debouncedHandler = useCallback(
    _.debounce((user: any, render: any, pagination: any) => {
      return fetchDateWithFilters(user, render, pagination);
    }, 700),
    []
  );
  async function fetchDateWithFilters(
    searchByName: any,
    render: any,
    pagination: any
  ) {
    let filters: { [index: string]: any } = {};
    if (searchByName) {
      filters.qtext = searchByName;
    }

    const localData = await callApi("v1/customer/list", "post", {
      pageNum: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      filters,
    });
    let arr = [];
    if (localData?.data?.status === "Success") {
      setAdminData([...localData.data.data.customerList]);
      setPageCount(
        Math.ceil(localData.data.data.customerCount / pagination.pageSize)
      );
      setTotalItems(localData.data.data.customerCount);
    }
  }

  const filterSearchData = (e: any) => {
    const search = e.target.value;

    setSearch(search);
  };

  useEffect(() => {
    debouncedHandler(search, render, pagination);
  }, [search, render, pagination]);

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };

  const handleDelete = async () => {
    if (eventData) {
      //dispatch(onDeleteEmployee(eventData.id));

      const res = await callApi(
        `v1/customer/${eventData?._id}/update`,
        "post",
        {
          customer: { is_deleted: true },
        }
      );
      if (res && res?.data?.status === "Success") {
        setDeleteModal(false);
        setRender(!render);
        toast("Deleted successfully");
      }
    }
  };
  //

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // employeeId: (eventData && eventData.employeeId) || "",
      first_name: (eventData && eventData.first_name) || "",
      last_name: (eventData && eventData.last_name) || "",
      // designation: (eventData && eventData.designation) || "",
      contact_email: (eventData && eventData.contact_email) || "",
      contact_phone: (eventData && eventData.contact_phone) || "",
      profile_type: (eventData && eventData.profile_type) || "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter First Name"),
      last_name: Yup.string().required("Please Enter Last Name"),
      // designation: Yup.string().required("Please Enter Designation"),
      contact_email: Yup.string().required("Please Enter Email"),
      contact_phone: Yup.string().required("Please Enter Phone"),
      profile_type: Yup.string().required("Please Enter Profile Type"),
      // experience: Yup.string().required("Please Enter Experience"),
      // joinDate: Yup.string().required("Please Enter Date"),
    }),

    onSubmit: async (values) => {
      if (isEdit) {
        const updateData = {
          _id: eventData ? eventData._id : 0,
          ...values,
        };
        // update user

        const res = await callApi(
          `v1/customer/${eventData?._id}/update`,
          "post",
          {
            customer: values,
          }
        );
        if (res && res?.data?.status === "Success") {
          setRender(!render);
          toast("Updated successfully");
        }
        // dispatch(onUpdateEmployee(updateData));
      } else {
        // const newData = {
        //   ...values,
        //   id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        //   employeeId:
        //     "#TWE1001" +
        //     (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        //   experience: values.experience + " year",
        // };
        // save new user
        // dispatch(onAddEmployee(newData));

        const res = await callApi(`v1/customer/new`, "post", {
          customer: {
            user_type: "Amateur Athlete",
            profile_type: values?.profile_type,
            first_name: values?.first_name,
            last_name: values?.last_name,
            contact_email: values?.contact_email,
            contact_phone: values?.contact_phone,
            country_code: "91",
          },
        });
        if (res && res?.data?.status === "Success") {
          setRender(!render);
          toast("User created successfully");
        }
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
      setSelectedImage("");
    } else {
      setShow(true);
      setEventData("");
      setSelectedImage("");
      validation.resetForm();
    }
  }, [show, validation]);

  const adminListColumns = useMemo(
    () => [
      // {
      //   header: "Created At",
      //   accessorKey: "created_at",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (cell) => (
      //     <>{moment(cell.getValue()).format("DD MMM YY HH:mm")}</>
      //   ),
      // },
      {
        header: "FirstName",
        accessorKey: "first_name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "LastName",
        accessorKey: "last_name",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Profile Type",
        accessorKey: "profile_type",

        enableColumnFilter: false,
        enableSorting: false,
        // cell: (cell: any) => (
        //   <Link to="#!" className="flex items-center gap-3">
        //     {/* <div className="rounded-full size-6 shrink-0 bg-slate-100"> */}
        //     {/* <img
        //         src={cell.row.original.professional_profile_id?.profile_type}
        //         alt=""
        //         className="h-6 rounded-full"
        //       /> */}
        //     {/* </div> */}
        //     <h6 className="grow">
        //       {_.upperFirst(
        //         cell.row.original.professional_profile_id?.profile_type
        //       )}
        //     </h6>
        //   </Link>
        // ),
      },

      {
        header: "Email",
        accessorKey: "contact_email",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Phone",
        accessorKey: "contact_phone",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <Dropdown className="relative">
              <Dropdown.Trigger
                id="orderAction5"
                data-bs-toggle="dropdown"
                className="flex items-center justify-center size-[30px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              >
                <MoreHorizontal className="size-3"></MoreHorizontal>
              </Dropdown.Trigger>
              <Dropdown.Content
                placement={cell.row.index ? "top-end" : "right-end"}
                className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
                aria-labelledby="orderAction5"
              >
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="#!"
                    onClick={() => {
                      const data = cell.row.original;
                      handleUpdateDataClick(data);
                    }}
                  >
                    <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                    <span className="align-middle">Edit</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="#!"
                    onClick={() => {
                      const data = cell.row.original;
                      onClickDelete(data);
                    }}
                  >
                    <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                    <span className="align-middle">Delete</span>
                  </Link>
                </li>
              </Dropdown.Content>
            </Dropdown>
          </>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <BreadCrumb title="Customers" pageTitle="Customers" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="grid grid-cols-12 gap-x-5">
        <div className="col-span-12 card 2xl:col-span-12">
          <div className="card-body">
            <div className="flex items-center">
              <h6 className="text-15 grow">Customers</h6>
              <div className="shrink-0">
                <button
                  type="button"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  onClick={toggle}
                >
                  <Plus className="inline-block size-4" />{" "}
                  <span className="align-middle">Add Customers</span>
                </button>
              </div>
            </div>
          </div>
          <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
            <form action="#!">
              <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                <div className="relative xl:col-span-3">
                  <input
                    type="text"
                    className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Search for name, email, phone number etc..."
                    autoComplete="off"
                    onChange={(e) => filterSearchData(e)}
                  />
                  <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                </div>
              </div>
            </form>
          </div>
          <div className="card-body">
            <TableContainer
              isPagination={true}
              columns={adminListColumns || []}
              data={adminData || []}
              pageCount={pageCount}
              pagination={pagination}
              totalItems={totalItems}
              onPaginationChange={(updater: any) => {
                const nextState = updater(pagination);
                if (nextState.pageIndex !== pagination.pageIndex)
                  setPagination(nextState);
              }}
              divclassName="overflow-x-auto"
              tableclassName="w-full whitespace-nowrap"
              theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
              thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
              tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
              PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
            />
          </div>
        </div>
      </div>
      {/* Employee Modal */}
      <Drawer
        show={show}
        onHide={toggle}
        id="drawerEnd"
        drawer-end="true"
        className="fixed inset-y-0 flex flex-col w-1/2 transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 z-drawer dark:bg-zink-600"
      >
        <Drawer.Header
          className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
          closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
        >
          <Drawer.Title className="text-15">
            {!!isEdit ? "Edit Customer" : "Add Customer"}
          </Drawer.Title>
          <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
        </Drawer.Header>
        <Drawer.Body className="h-full p-4 overflow-y-auto">
          <form
            className="create-form"
            id="create-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <input type="hidden" value="" name="id" id="id" />
            <input type="hidden" value="add" name="action" id="action" />
            <input type="hidden" id="id-field" />
            <div
              id="alert-error-msg"
              className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20"
            ></div>
            <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
              <div className="xl:col-span-6">
                <label
                  htmlFor="phoneNumberInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="phoneNumberInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter First Name"
                  name="first_name"
                  onChange={validation.handleChange}
                  value={validation.values.first_name || ""}
                />
                {validation.touched.phone && validation.errors.phone ? (
                  <p className="text-red-400">{validation.errors.phone}</p>
                ) : null}
              </div>
              <div className="xl:col-span-6">
                <label
                  htmlFor="locationInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="locationInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter Last Name"
                  name="last_name"
                  onChange={validation.handleChange}
                  value={validation.values.last_name || ""}
                />
                {validation.touched.location && validation.errors.location ? (
                  <p className="text-red-400">{validation.errors.location}</p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="employeeInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  id="employeeInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Phone Number"
                  name="contact_phone"
                  onChange={validation.handleChange}
                  value={validation.values.contact_phone || ""}
                />
                {validation.touched.contact_phone &&
                validation.errors.contact_phone ? (
                  <p className="text-red-400">
                    {validation.errors.contact_phone}
                  </p>
                ) : null}
              </div>
              <div className="xl:col-span-12">
                <label
                  htmlFor="emailInput"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Email
                </label>
                <input
                  type="text"
                  id="emailInput"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                  placeholder="Enter Email "
                  name="contact_email"
                  onChange={validation.handleChange}
                  value={validation.values.contact_email || ""}
                />
                {validation.touched.contact_email &&
                validation.errors.contact_email ? (
                  <p className="text-red-400">
                    {validation.errors.contact_email}
                  </p>
                ) : null}
              </div>

              <div className="xl:col-span-6">
                <label
                  htmlFor="status"
                  className="inline-block mb-2 text-base font-medium"
                >
                  Profile Type
                </label>

                <select
                  onChange={validation.handleChange}
                  value={validation.values.profile_type || ""}
                  name="profile_type"
                  id="status"
                  className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                >
                  <option value="">Select</option>
                  <option value="amateur">Amature Athelete</option>
                  <option value="coach">Coach</option>
                  <option value="sports-academy">Sports Academy</option>
                  <option value="sports-industry">
                    Corporate Sponsor/manufacture
                  </option>
                  <option value="sports-fan">Sports Fan</option>
                  <option value="health-professional">
                    Health Professionals
                  </option>
                  <option value="pro-athlete">Pro Athlete</option>
                </select>
              </div>
            </div>
            <div className="flex justify-end gap-2 mt-4">
              <button
                type="reset"
                id="close-modal"
                data-modal-close="addEmployeeModal"
                className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                onClick={toggle}
              >
                Cancel
              </button>
              <button
                type="submit"
                id="addNew"
                className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update Customer" : "Add Customer"}
              </button>
            </div>
          </form>
        </Drawer.Body>
      </Drawer>
    </React.Fragment>
  );
};

export default UserList;
