// dashboard

import UserProfile from "pages/Authentication/UserProfile";
import Login from "pages/Authentication/Login";
import ForgetPassword from "pages/Authentication/forgetPassword";
import Logout from "pages/Authentication/LogOut";
import UserList from "pages/Customer/UserList";
import blog from "pages/blog";
import Admins from "pages/Admin";


import ResetPassword from "pages/Authentication/ResetPassword";

import MediaList from "pages/mediaList";
import BlogAuther from "pages/blogAuthor";
interface RouteObject {
  path: string;
  component: React.ComponentType<any>; // Use React.ComponentType to specify the type of the component
  exact?: boolean;
}

const authProtectedRoutes: Array<RouteObject> = [
  // Dashboard
  // { path: "/", component: Home },

  // profile

  //userlist
  { path: "/admins", component: Admins },
  { path: "/", component: UserList },
  { path: "/media", component: MediaList },
  { path: "/user-profile", component: UserProfile },
  { path: "/blog", component: blog },
  {path:"/blog-auther", component: BlogAuther}
];

const publicRoutes = [
  // authentication
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/logout", component: Logout },
];

export { authProtectedRoutes, publicRoutes };
