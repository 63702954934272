import React, {
  useMemo,
  useRef,
  useState,
  useCallback,
  useEffect,
} from "react";
import { Slate, Editable, withReact } from "slate-react";
import BreadCrumb from "Common/BreadCrumb";
import { PaginationState } from "@tanstack/react-table";
import TableContainer from "Common/TableContainer";
import { Link } from "react-router-dom";
import {
  MoreHorizontal,
  Eye,
  FileEdit,
  Trash2,
  UploadCloud,
} from "lucide-react";
import { Dropdown } from "Common/Components/Dropdown";
import Select from "react-select";
import { Download, Plus, Search, SlidersHorizontal } from "lucide-react";
import moment from "moment";
import JoditEditor, { IJoditEditorProps } from "jodit-react";
import HTMLReactParser from "html-react-parser";
import Flatpickr from "react-flatpickr";
import DeleteModal from "Common/DeleteModal";
import callApi from "Common/utils/apiCaller";
import { Pencil } from "lucide-react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import _ from "lodash";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Drawer from "Common/Components/Drawer";
import { X } from "lucide-react";
import { getImageUrl } from "Common/utils/ImageDomain";
import Modal from "Common/Components/Modal";
import { Label } from "@headlessui/react/dist/components/label/label";

const url = `https://apidev.superday.cloud`;

const Blog = () => {
  const [adminData, setAdminData] = useState([]);
  const [search, setSearch] = useState<any>();
  const [showModel, setShowModel] = useState<boolean>(false);
  const [read, setRead] = useState<any>();
  const [blogType, setBlogType] = useState<any>();
  const defaultToggle = () => setShowModel(!showModel);
  const [imageLink, setImageLink] = useState<any>("");
  const [author, setAuthor] = useState<any>();
  const [authorId, setAuthorId] = useState<any>("");
  const [pageCount, setPageCount] = useState(0);
  const [eventData, setEventData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState(0);
  const [render, setRender] = useState(false);
  const [authorList, setAuthorList] = useState<any>();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 30,
  });
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const editor = useRef(null);
  const [content, setContent] = useState<any>("");

  const deleteToggle = () => setDeleteModal(!deleteModal);
  const debouncedHandler = useCallback(
    _.debounce((user: any, render: any, pagination: any) => {
      return fetchDateWithFilters(user, render, pagination);
    }, 700),
    []
  );
  async function fetchDateWithFilters(
    searchByName: any,
    render: any,
    pagination: any
  ) {
    let filters: { [index: string]: any } = {};
    if (searchByName) {
      filters.qtext = searchByName;
    }

    const localData = await callApi("v1/blog/list", "post", {
      pageNum: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,
      filters,
    });
    let arr = [];
    if (localData?.data?.status === "Success") {
      console.log(localData);

      setAdminData([...localData.data.data.blogList]);
      setPageCount(
        Math.ceil(localData.data.data.blogCount / pagination.pageSize)
      );
      setTotalItems(localData.data.data.blogCount);
    }
  }

  const filterSearchData = (e: any) => {
    const search = e.target.value;

    setSearch(search);
  };

  useEffect(() => {
    debouncedHandler(search, render, pagination);
  }, [search, render, pagination]);

  const onAutherList = async () => {
    const localData = await callApi("v1/blog-author/list", "post", {
      pageNum: 1,
      pageSize: 100,
    });
    let arr = [];
    if (localData?.data?.status === "Success") {
      for (let item of localData?.data?.data.blog_authorList) {
        arr.push({ label: item.name, value: item.name });
      }
      // arr.push({ label: "Select author name", value: "Select..." });
      setAuthorList(arr);
      setAuthor(localData?.data?.data.blog_authorList);
    }
  };

  useEffect(() => {
    if (!authorList) {
      onAutherList();
    }
  }, [authorList]);

  const handleAuthorList = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setAuthorId("");
    } else {
      for (let item of author) {
        if (item.name === selectedOption.value) {
          setAuthorId(item._id);
        }
      }
    }
  };

  const options = [
    { value: "Select...", label: "Select Blog Type" },
    { value: "blogs", label: "blogs" },
    { value: "tools", label: "tools" },
    { value: "script", label: "script" },
  ];

  const handleChange = (selectedOption: any) => {
    if (selectedOption.value === "Select...") {
      setBlogType("");
    } else {
      setBlogType(selectedOption.value);
    }
  };

  const handleRemoveImage = (id: any) => {
    setImageLink("");
  };
  const uploadPhoto = async (file: any) => {
    let bodyFormData = new FormData();
    bodyFormData.append("file", file);
    const res = await axios({
      method: "post",
      url: `${url}/api/v1/media/upload`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (res?.data?.status === "Success") {
      setImageLink(res.data.data.link);
    }
  };
  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell._id) {
      setEventData(cell);
    }
  };

  const handleDelete = async () => {
    if (eventData) {
      //dispatch(onDeleteEmployee(eventData.id));

      const res = await callApi(`v1/blog/${eventData?._id}/update`, "post", {
        blog: { is_deleted: true },
      });
      if (res && res?.data?.status === "Success") {
        setDeleteModal(false);
        setRender(!render);
        toast("Deleted successfully");
      }
    }
  };

  //IJoditEditorProps["config"]
  const config = {
    readonly: false, // Ensures the editor is editable
    uploader: {
      insertImageAsBase64URI: true,
    },
    buttons: [
      "bold",
      "italic",
      "underline",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "|",
      "image",
      "|",
      "align",
      "undo",
      "redo",
      "|",
      "hr",
      "eraser",
      "fullsize",
    ],
  };

  // Update Data
  const handleUpdateDataClick = (ele: any) => {
    setEventData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };

  // validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      heading: (eventData && eventData.heading) || "",
    },
    validationSchema: Yup.object({
      heading: Yup.string().required("Please Enter Title"),
    }),

    onSubmit: async (values) => {
      if (isEdit) {
        const updateData = {
          _id: eventData ? eventData._id : 0,
          ...values,
        };
        // update user

        const res = await callApi(`v1/blog/${eventData?._id}/update`, "post", {
          blog: {
            ...values,
            blog_type: blogType,
            thumbnail_url: imageLink,
            text: content,
            author: authorId,
          },
        });
        if (res && res?.data?.status === "Success") {
          setRender(!render);
          toast("Updated successfully");
        }
        // dispatch(onUpdateEmployee(updateData));
      } else {
        // const newData = {
        //   ...values,
        //   id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        //   employeeId:
        //     "#TWE1001" +
        //     (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
        //   experience: values.experience + " year",
        // };
        // save new user
        // dispatch(onAddEmployee(newData));

        const res = await callApi(`v1/blog/new`, "post", {
          blog: {
            blog_type: blogType,
            author: authorId,
            thumbnail_url: imageLink,
            text: content,
            heading: values?.heading,
          },
        });
        if (res && res?.data?.status === "Success") {
          setRender(!render);
          toast("User created successfully");
        }
      }
      toggle();
    },
  });

  const toggle = useCallback(() => {
    if (show) {
      setShow(false);
      setEventData("");
      setIsEdit(false);
    } else {
      setShow(true);
      setEventData("");

      validation.resetForm();
    }
  }, [show, validation]);
  interface autherOption {
    readonly value: string;
    readonly label: string;
  }
  const opAuther: readonly autherOption[] = authorList;
  const filterColors = (inputValue: string) => {
    return opAuther.filter((i: any) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const loadOptions = (
    inputValue: string,
    callback: (options: autherOption[]) => void
  ) => {
    setTimeout(() => {
      callback(filterColors(inputValue));
    }, 1000);
  };

  const adminListColumns = useMemo(
    () => [
      // {
      //   header: "Created At",
      //   accessorKey: "created_at",
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (cell) => (
      //     <>{moment(cell.getValue()).format("DD MMM YY HH:mm")}</>
      //   ),
      // },
      {
        header: "Blog Type",
        accessorKey: "blog_type",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Thumbnail Url",
        accessorKey: "thumbnail_url",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Link
            to={cell.row.original.thumbnail_url}
            className="flex items-center gap-3 "
            target="_blank"
          >
            <div className="rounded-full size-6 shrink-0 bg-slate-100">
              <img
                src={cell.row.original.thumbnail_url}
                alt=""
                className="h-6 rounded-full"
              />
            </div>
            {/* <h6 className="grow">
              {_.upperFirst(
                cell.row.original.professional_profile_id?.profile_type
              )}
            </h6> */}
          </Link>
        ),
      },
      {
        header: "Heading",
        accessorKey: "heading",

        enableColumnFilter: false,
        enableSorting: false,
        // cell: (cell: any) => (
        //   <Link to="#!" className="flex items-center gap-3">
        //     {/* <div className="rounded-full size-6 shrink-0 bg-slate-100"> */}
        //     {/* <img
        //         src={cell.row.original.professional_profile_id?.profile_type}
        //         alt=""
        //         className="h-6 rounded-full"
        //       /> */}
        //     {/* </div> */}
        //     <h6 className="grow">
        //       {_.upperFirst(
        //         cell.row.original.professional_profile_id?.profile_type
        //       )}
        //     </h6>
        //   </Link>
        // ),
      },

      {
        header: "Text",
        accessorKey: "text",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <Link to="#!" className="flex items-center gap-3">
            <div>
              {HTMLReactParser(cell.row.original.text?.slice(0, 30) ?? "")}

              {cell.row.original.text?.length > 30 ? (
                <>
                  {" ..."}
                  <span
                    style={{
                      color: "#fb801d",
                    }}
                    onClick={() => {
                      // setReadText("Text");
                      setRead(cell.row.original.text);
                      defaultToggle();
                    }}
                  >
                    more
                  </span>
                </>
              ) : null}
            </div>
          </Link>
        ),
        // cell: (cell: any) => (
        //   <Link to="#!" className="flex items-center gap-3">
        //     {/* <div className="rounded-full size-6 shrink-0 bg-slate-100"> */}
        //     {/* <img
        //         src={cell.row.original.professional_profile_id?.profile_type}
        //         alt=""
        //         className="h-6 rounded-full"
        //       /> */}
        //     {/* </div> */}
        //     <h6 className="grow">
        //       <div>{HTMLReactParser(cell.row.original.text ?? "")}</div>
        //     </h6>
        //   </Link>
        // ),
      },
      {
        header: "Author",
        accessorKey: "author",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => (
          <>
            <Dropdown className="relative">
              <Dropdown.Trigger
                id="orderAction5"
                data-bs-toggle="dropdown"
                className="flex items-center justify-center size-[30px] dropdown-toggle p-0 text-slate-500 btn bg-slate-100 hover:text-white hover:bg-slate-600 focus:text-white focus:bg-slate-600 focus:ring focus:ring-slate-100 active:text-white active:bg-slate-600 active:ring active:ring-slate-100 dark:bg-slate-500/20 dark:text-slate-400 dark:hover:bg-slate-500 dark:hover:text-white dark:focus:bg-slate-500 dark:focus:text-white dark:active:bg-slate-500 dark:active:text-white dark:ring-slate-400/20"
              >
                <MoreHorizontal className="size-3"></MoreHorizontal>
              </Dropdown.Trigger>
              <Dropdown.Content
                placement={cell.row.index ? "top-end" : "right-end"}
                className="absolute z-50 py-2 mt-1 ltr:text-left rtl:text-right list-none bg-white rounded-md shadow-md dropdown-menu min-w-[10rem] dark:bg-zink-600"
                aria-labelledby="orderAction5"
              >
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="#!"
                    onClick={() => {
                      const data = cell.row.original;
                      handleUpdateDataClick(data);
                    }}
                  >
                    <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                    <span className="align-middle">Edit</span>
                  </Link>
                </li>
                <li>
                  <Link
                    className="block px-4 py-1.5 text-base transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:bg-slate-100 hover:text-slate-500 focus:bg-slate-100 focus:text-slate-500 dark:text-zink-100 dark:hover:bg-zink-500 dark:hover:text-zink-200 dark:focus:bg-zink-500 dark:focus:text-zink-200"
                    to="#!"
                    onClick={() => {
                      const data = cell.row.original;
                      onClickDelete(data);
                    }}
                  >
                    <Trash2 className="inline-block size-3 ltr:mr-1 rtl:ml-1" />{" "}
                    <span className="align-middle">Delete</span>
                  </Link>
                </li>
              </Dropdown.Content>
            </Dropdown>
          </>
        ),
      },
    ],
    []
  );

  return (
    // <Slate
    //   editor={editor}
    //   initialValue={initialValue} // Use initialValue instead of value
    //   onChange={(newValue: Descendant[]) => {
    //     console.log(newValue);
    //   }}
    // >
    //   <Editable />
    // </Slate>
    <>
      <React.Fragment>
        <BreadCrumb title="Blogs" pageTitle="Blogs" />
        <DeleteModal
          show={deleteModal}
          onHide={deleteToggle}
          onDelete={handleDelete}
        />
        <ToastContainer closeButton={false} limit={1} />
        <div className="grid grid-cols-12 gap-x-5">
          <div className="col-span-12 card 2xl:col-span-12">
            <div className="card-body">
              <div className="flex items-center">
                <h6 className="text-15 grow">Blogs</h6>
                <div className="shrink-0">
                  <button
                    type="button"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                    onClick={toggle}
                  >
                    <Plus className="inline-block size-4" />{" "}
                    <span className="align-middle">Add Blogs</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
              <form action="#!">
                <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                  <div className="relative xl:col-span-3">
                    <input
                      type="text"
                      className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      placeholder="Search for name, email, phone number etc..."
                      autoComplete="off"
                      onChange={(e) => filterSearchData(e)}
                    />
                    <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                  </div>
                </div>
              </form>
            </div>
            <div className="card-body">
              <TableContainer
                isPagination={true}
                columns={adminListColumns || []}
                data={adminData || []}
                pageCount={pageCount}
                pagination={pagination}
                totalItems={totalItems}
                onPaginationChange={(updater: any) => {
                  const nextState = updater(pagination);
                  if (nextState.pageIndex !== pagination.pageIndex)
                    setPagination(nextState);
                }}
                divclassName="overflow-x-auto"
                tableclassName="w-full whitespace-nowrap"
                theadclassName="ltr:text-left rtl:text-right bg-slate-100 text-slate-500 dark:text-zink-200 dark:bg-zink-600"
                thclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 font-semibold border-y border-slate-200 dark:border-zink-500"
                tdclassName="px-3.5 py-2.5 first:pl-5 last:pr-5 border-y border-slate-200 dark:border-zink-500"
                PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
              />
            </div>
          </div>
        </div>
        {/* Employee Modal */}
        <Drawer
          show={show}
          onHide={toggle}
          id="drawerEnd"
          drawer-end="true"
          className="fixed inset-y-0 flex flex-col w-1/2 transition-transform duration-300 ease-in-out transform bg-white shadow ltr:right-0 rtl:left-0 z-drawer dark:bg-zink-600"
        >
          <Drawer.Header
            className="flex items-center justify-between p-4 border-b card-body border-slate-200 dark:border-zink-500"
            closeButtonClass="lucide lucide-x size-4 transition-all duration-200 ease-linear text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"
          >
            <Drawer.Title className="text-15">
              {!!isEdit ? "Edit Blog" : "Add Blog"}
            </Drawer.Title>
            <X className="transition-all duration-200 ease-linear size-4 text-slate-500 hover:text-slate-700 dark:text-zink-200 dark:hover:text-zink-50"></X>
          </Drawer.Header>
          <Drawer.Body className="h-full p-4 overflow-y-auto">
            <form
              className="create-form"
              id="create-form"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              <input type="hidden" value="" name="id" id="id" />
              <input type="hidden" value="add" name="action" id="action" />
              <input type="hidden" id="id-field" />
              <div
                id="alert-error-msg"
                className="hidden px-4 py-3 text-sm text-red-500 border border-transparent rounded-md bg-red-50 dark:bg-red-500/20"
              ></div>
              <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                <div className="xl:col-span-12">
                  <label
                    htmlFor="phoneNumberInput3"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Blog Type
                  </label>
                  <Select
                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    options={options}
                    isSearchable={true}
                    defaultValue={options[0]}
                    onChange={(event: any) => handleChange(event)}
                    id="choices-single-default"
                  />
                </div>
                <div className="xl:col-span-12">
                  <label
                    htmlFor="phoneNumberInput2"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Title
                  </label>
                  <input
                    type="text"
                    id="phoneNumberInput2"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter Title"
                    name="heading"
                    onChange={validation.handleChange}
                    value={validation.values.heading || ""}
                  />
                  {validation.touched.heading && validation.errors.heading ? (
                    <p className="text-red-400">{validation.errors.heading}</p>
                  ) : null}
                </div>
                <div className="xl:col-span-12">
                  <label
                    htmlFor="phoneNumberInput1"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Author
                  </label>
                  <div className="xl:col-span-2">
                    {authorList ? (
                      // <Select
                      //   className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                      //   options={authorList}
                      //   isSearchable={false}
                      //   defaultValue={authorList[0]}
                      //   onChange={(event: any) => handleAuthorList(event)}
                      //   id="choices-single-default"
                      // />
                      <AsyncSelect
                        className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                        cacheOptions
                        loadOptions={loadOptions}
                        onChange={(event: any) => handleAuthorList(event)}
                        id="choices-single-default1"
                        defaultOptions
                      />
                    ) : null}
                  </div>
                </div>
                <div className="xl:col-span-12">
                  <label
                    htmlFor="phoneNumberIn"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Text
                  </label>
                  <JoditEditor
                    ref={editor}
                    config={config}
                    value={content}
                    onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                    onChange={(newContent) => {}}
                  />
                  {/* <div style={{ marginTop: "20px" }}>
                    <h3>Editor Output:</h3>
                    {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
                  {/* <div>{HTMLReactParser(content)}</div> */}
                  {/* </div>  */}
                </div>
              </div>
              {imageLink === "" ? (
                <div
                  style={{ border: "1px solid #e2e8f0", padding: "10px" }}
                  className="flex items-center justify-center gap-2 mt-4 border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 "
                >
                  <input
                    type="file"
                    hidden
                    id="uploadimage49"
                    accept="image/*"
                    className="hidden profile-img-file-input"
                    onChange={(e: any) => uploadPhoto(e.target.files[0])}
                  />
                  <label
                    htmlFor="uploadimage49"
                    className="flex flex-col items-center justify-center cursor-pointer "
                  >
                    <div className="mb-3">
                      <UploadCloud className="block mx-auto size-12 text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                    </div>

                    <h5 className="mb-0 font-normal text-slate-500 text-15">
                      Drag and drop your files or <a href="#!">browse</a> your
                      files
                    </h5>
                  </label>
                </div>
              ) : (
                <div>
                  <div
                    className="flex flex-wrap justify-center gap-2 mt-4"
                    style={{
                      border: "1px solid #e2e8f0",
                      position: "relative",
                    }}
                  >
                    <>
                      <img
                        src={imageLink}
                        width={100}
                        alt={"img"}
                        style={{
                          margin: "10px",
                          borderRadius: "10px",
                          height: "100px",
                        }}
                      />

                      <div
                        style={
                          {
                            // position: "absolute",
                          }
                        }
                        onClick={handleRemoveImage}
                      >
                        <X></X>
                      </div>
                    </>
                  </div>

                  {/* <div className="flex justify-center gap-2 mt-4">
                  <input
                    type="file"
                    hidden
                    id="uploadimage79"
                    onChange={(e: any) => uploadPhoto(e.target.files[0])}
                  />

                  <label
                    htmlFor="uploadimage79"
                    className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    Upload More Media
                  </label>
                </div> */}
                </div>
              )}
              <div className="flex justify-end gap-2 mt-4">
                <button
                  type="reset"
                  id="close-modal"
                  data-modal-close="addEmployeeModal"
                  className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10"
                  onClick={toggle}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  id="addNew"
                  className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                >
                  {!!isEdit ? "Update Blog" : "Add Blog"}
                </button>
              </div>
            </form>
          </Drawer.Body>
        </Drawer>
        <Modal
          show={showModel}
          onHide={defaultToggle}
          id="defaultModal"
          modal-center="true"
          className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
          dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600 flex flex-col h-full"
        >
          <Modal.Header
            className="flex items-center justify-between p-4 border-b border-slate-200 dark:border-zink-500"
            closeButtonClass="transition-all duration-200 ease-linear text-slate-500 hover:text-red-500 dark:text-zink-200 dark:hover:text-red-500"
          >
            <Modal.Title className="text-16">{"Text"}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
            {/* <h5 className="mb-3 text-16">{}</h5> */}
            <p className="text-slate-500 dark:text-zink-200">
              {HTMLReactParser(read ?? "")}
            </p>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    </>
  );
};

export default Blog;
