import React from "react";
import { Facebook, Github, Mail, Twitter } from "lucide-react";
import meta from "../../../package.json";
// Formik validation
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik as useFormic } from "formik";
import { toast } from "react-toastify";
// Image
import logoLight from "assets/images/logo-light.png";
import logoDark from "assets/images/logo-dark.png";
import { loginUser } from "slices/thunk";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "Common/withRouter";
import { createSelector } from "reselect";
import AuthIcon from "pages/AuthenticationInner/AuthIcon";
import { Link } from "react-router-dom";
import callApi from "Common/utils/apiCaller";
const ForgetPassword = () => {
  // const dispatch = useDispatch<any>();

  // const selectLogin = createSelector(
  //   (state: any) => state.Login,
  //   (login) => ({
  //     success: login.success,
  //     error: login.error,
  //   })
  // );

  // const { success, error } = useSelector(selectLogin);

  const validation: any = useFormic({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your email"),
    }),
    onSubmit: async (values: any) => {
      // dispatch(loginUser(values, props.router.navigate));
      const res = await callApi("v1/admin//forgot-password", "post", {
        admin: {
          contact_email: values?.email,
        },
      });
      if (res.status === "Success") {
        toast("The link has been Send to your registered EmailId");
      }
    },
  });

  React.useEffect(() => {
    const bodyElement = document.body;

    bodyElement.classList.add(
      "flex",
      "items-center",
      "justify-center",
      "min-h-screen",
      "py-16",
      "lg:py-10",
      "bg-slate-50",
      "dark:bg-zink-800",
      "dark:text-zink-100",
      "font-public"
    );

    return () => {
      bodyElement.classList.remove(
        "flex",
        "items-center",
        "justify-center",
        "min-h-screen",
        "py-16",
        "lg:py-10",
        "bg-slate-50",
        "dark:bg-zink-800",
        "dark:text-zink-100",
        "font-public"
      );
    };
  }, []);
  return (
    <>
      <ToastContainer closeButton={false} limit={1} />
      <React.Fragment>
        <div className="relative">
          <AuthIcon />

          <div className="mb-0 w-screen lg:mx-auto lg:w-[500px] card shadow-lg border-none shadow-slate-100 relative">
            <div className="!px-10 !py-12 card-body">
              <form
                className="mt-10"
                id="signInForm"
                onSubmit={(event: any) => {
                  event.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="inline-block mb-2 text-base font-medium"
                  >
                    Email ID
                  </label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                    placeholder="Enter email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <div id="email-error" className="mt-1 text-sm text-red-500">
                      {validation.errors.email}
                    </div>
                  ) : null}
                </div>

                <div className="mt-10">
                  <button
                    type="submit"
                    className="w-full text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};

export default ForgetPassword;
