import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// login
import LoginReducer from "./auth/login/reducer";


// userProfile
import ProfileReducer from "./auth/profile/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,

  Profile: ProfileReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
