import {
  Award,
  CalendarDays,
  CircuitBoard,
  Codesandbox,
  FileText,
  Handshake,
  LifeBuoy,
  LocateFixed,
  Mail,
  Map,
  MessageSquare,
  MonitorDot,
  PackagePlus,
  PictureInPicture2,
  PieChart,
  RadioTower,
  ScrollText,
  Share2,
  ShoppingBag,
  Table,
  Building,
  Trophy,
  UserCog,
  UserCog2,
  UserRound,
} from "lucide-react";

const menuData: any = [
  {
    label: "menu",
    isTitle: true,
  },
  {
    id: "adminlist",
    label: "Admins",
    link: "/admins",
    icon: <LocateFixed />,
  },
  {
    id: "userlist",
    label: "Customers",
    link: "/",
    icon: <UserRound />,
  },
  {
    id: "media",
    label: "Media",
    link: "/media",
    icon: <UserRound />,
  },
  {
    id: "blog",
    label: "Blog",
    link: "/blog",
    icon: <MonitorDot />,
  },
  {
    id: "blog-auther",
    label: "BlogAuther",
    link: "/blog-auther",
    icon: <MonitorDot />,
  },
];

export { menuData };
